.profile {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 2rem;
    width: 90%;
    max-width: 400px;
    background-color: #1b2028;
    border-radius: 16px;
    position: relative;
    border: 3px solid transparent;
    background-clip: padding-box;
    text-align: center;
    color: #f1f3f3;
    background-image: linear-gradient(
            135deg,
            rgba(117, 46, 124, 0.35),
            rgba(115, 74, 88, 0.1) 15%,
            #1b2028 20%,
            #1b2028 100%
    );
}

.profile :after {
     content: "";
     display: block;
     top: -5px;
     left: -5px;
     bottom: -5px;
     right: -5px;
     z-index: -1;
     position: absolute;
     border-radius: 16px;
     background-image: linear-gradient(
             135deg,
             #D9550D,
             #FFB000 20%,
             #1b2028 30%,
             #2c333e 60%,
             #FFB000 90%,
             #D9550D 100%
     );
}


.profile-image {
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    height: 300px;
    position: relative;
}

.profile-image > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.profile-username {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1.5rem;
}

.profile-user-handle {
    color: #7d8396;
}

.profile-actions {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile-actions > * {
   margin: 0 0.25rem;
}

.btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 1;
    transition: 0.15s ease;
    width: 50%;
}
.btn--primary {
    border-radius: 99em;
    background-image: linear-gradient(120deg, #ff8800, #ff3300);;
    color: #fff;
    padding: 0 1.375em;
}

.btn:hover, .btn:focus {
     background-size: 150%;
}

.profile-links {
    margin-top: 3.5rem;
}

.link {
    text-decoration: none;
    color: #7d8396;
    margin-left: 0.375rem;
    margin-right: 0.375rem;
}

.link i {
    font-size: 1.25em;
}
