* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--tg-theme-text-color)
}

.accordion-button:not(.collapsed) {
  background-color: #D9550D !important;
  color: #FFFFFF !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button:focus {
  border-color: #D9550D !important;
  box-shadow: none !important;
}

.main-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.2);
}
.image {
  height: 300px;
  width: 300px;
  position: absolute;
  top: 25px;
  left: 15px;
}
.telegram-button{
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  height: 45px;
  width: 150px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #0088cc;
  border-radius: 3px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 11px;
  color: #FFF;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.telegram-button:hover{
  color: #FFF;
  background-color: #007dbb;
}

.telegram-button:active{
  background-color: #026698;
}

.links-container {
  position: absolute;
  bottom: 15px;
  display: flex;
  gap: 10px;
}

.App {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

@keyframes logo-spin {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes motto-spin {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg);
  }
}

.motto {
  width: 350px;
  height: 350px;
  animation: motto-spin 20s linear infinite;
}

.logo {
  animation: logo-spin 10s linear infinite;
}
